'use client';

import { SvgIconProps, SvgIconTypeMap } from '@/components/ui';
import { SprinklesProps, useSprinklesProps } from '@/theme';

import Check from '@mui/icons-material/Check';
import Email from '@mui/icons-material/Email';
import Error from '@mui/icons-material/Error';
import Insight from '@mui/icons-material/Lightbulb';
import Link from '@mui/icons-material/Link';
import Lock from '@mui/icons-material/Lock';
import Newspaper from '@mui/icons-material/Newspaper';
import Eye from '@mui/icons-material/Visibility';
import EyeOff from '@mui/icons-material/VisibilityOff';
import Twitter from '@mui/icons-material/X';

import BankingAndFinance from '../../../public/svg/area/bankingAndFinance.svg';
import BusinessAndHumanRights from '../../../public/svg/area/businessAndHumanRights.svg';
import CapitalMarkets from '../../../public/svg/area/capitalMarkets.svg';
import CharitiesNotForProfitAndPhilanthropy from '../../../public/svg/area/charitiesNotForProfitAndPhilanthropy.svg';
import CompetitionAndConsumerLaw from '../../../public/svg/area/competitionAndConsumerLaw.svg';
import ConsumerCredit from '../../../public/svg/area/consumerCredit.svg';
import CorporateAndCommercial from '../../../public/svg/area/corporateAndCommercial.svg';
import Cyber from '../../../public/svg/area/cyber.svg';
import DebtRecovery from '../../../public/svg/area/debtRecovery.svg';
import Defamation from '../../../public/svg/area/defamation.svg';
import EducationAndTraining from '../../../public/svg/area/educationAndTraining.svg';
import EmploymentAndWorkplaceRelations from '../../../public/svg/area/employmentAndWorkplaceRelations.svg';
import EnergyResourcesAndUtilities from '../../../public/svg/area/energyResourcesAndUtilities.svg';
import EnvironmentSocialAndGovernance from '../../../public/svg/area/environmentSocialAndGovernance.svg';
import FamilyOfficeServices from '../../../public/svg/area/familyOfficeServices.svg';
import FashionAndBeauty from '../../../public/svg/area/fashionAndBeauty.svg';
import FinancialServices from '../../../public/svg/area/financialServices.svg';
import Fintech from '../../../public/svg/area/fintech.svg';
import Franchising from '../../../public/svg/area/franchising.svg';
import Gaming from '../../../public/svg/area/gaming.svg';
import GlobalMobilityServices from '../../../public/svg/area/globalMobilityServices.svg';
import HealthAndCommunity from '../../../public/svg/area/healthAndCommunity.svg';
import InsolvencyAndRestructuring from '../../../public/svg/area/insolvencyAndRestructuring.svg';
import Insurance from '../../../public/svg/area/insurance.svg';
import IntellectualProperty from '../../../public/svg/area/intellectualProperty.svg';
import InvestmentFunds from '../../../public/svg/area/investmentFunds.svg';
import LitigationAndDisputeResolution from '../../../public/svg/area/litigationAndDisputeResolution.svg';
import MergersAndAcquisitions from '../../../public/svg/area/mergersAndAcquisitions.svg';
import MigrationServices from '../../../public/svg/area/migrationServices.svg';
import Privacy from '../../../public/svg/area/privacy.svg';
import PrivateCapital from '../../../public/svg/area/privateCapital.svg';
import PrivateClients from '../../../public/svg/area/privateClients.svg';
import PropertyAndProjects from '../../../public/svg/area/propertyAndProjects.svg';
import PublicSector from '../../../public/svg/area/publicSector.svg';
import RegulationAndCompliance from '../../../public/svg/area/regulationAndCompliance.svg';
import RetailAndFmcg from '../../../public/svg/area/retailAndFmcg.svg';
import SocialAndAffordableHousing from '../../../public/svg/area/socialAndAffordableHousing.svg';
import SportsAndEntertainment from '../../../public/svg/area/sportsAndEntertainment.svg';
import Superannuation from '../../../public/svg/area/superannuation.svg';
import Tax from '../../../public/svg/area/tax.svg';
import TechnologyAndDigitalEconomy from '../../../public/svg/area/technologyAndDigitalEconomy.svg';
import TransportAndTrade from '../../../public/svg/area/transportAndTrade.svg';
import TurnaroundAndBusinessRestructuring from '../../../public/svg/area/turnaroundAndBusinessRestructuring.svg';
import WorkHealthSafety from '../../../public/svg/area/workHealthSafety.svg';

import ArrowDownward from '../../../public/svg/arrowDownward.svg';
import ArrowLeft from '../../../public/svg/arrowLeft.svg';
import ArrowRight from '../../../public/svg/arrowRight.svg';
import ArrowUpward from '../../../public/svg/arrowUpward.svg';
import Calendar from '../../../public/svg/calendar.svg';
import ChevronDown from '../../../public/svg/chevronDown.svg';
import ChevronUp from '../../../public/svg/chevronUp.svg';
import Close from '../../../public/svg/close.svg';
import Download from '../../../public/svg/download.svg';
import Event from '../../../public/svg/event.svg';
import Facebook from '../../../public/svg/facebook.svg';
import Instagram from '../../../public/svg/instagram.svg';
import LinkedIn from '../../../public/svg/linkedin.svg';
import Menu from '../../../public/svg/menu.svg';
import Minus from '../../../public/svg/minus.svg';
import Plus from '../../../public/svg/plus.svg';
import Podcasts from '../../../public/svg/podcast.svg';
import ReturnRight from '../../../public/svg/returnRight.svg';
import Search from '../../../public/svg/search.svg';
import Share from '../../../public/svg/share.svg';
import Video from '../../../public/svg/video.svg';
import YouTube from '../../../public/svg/youtube.svg';

import { SvgIcon } from '@mui/material';
import * as style from './Icon.css';

export const areaIcons = {
  investmentFunds: InvestmentFunds,
  bankingAndFinance: BankingAndFinance,
  familyOfficeServices: FamilyOfficeServices,
  privateCapital: PrivateCapital,
  healthAndCommunity: HealthAndCommunity,
  businessAndHumanRights: BusinessAndHumanRights,
  financialServices: FinancialServices,
  privateClients: PrivateClients,
  publicSector: PublicSector,
  capitalMarkets: CapitalMarkets,
  fintech: Fintech,
  propertyAndProjects: PropertyAndProjects,
  retailAndFmcg: RetailAndFmcg,
  charitiesNotForProfitAndPhilanthropy: CharitiesNotForProfitAndPhilanthropy,
  franchising: Franchising,
  regulationAndCompliance: RegulationAndCompliance,
  socialAndAffordableHousing: SocialAndAffordableHousing,
  competitionAndConsumerLaw: CompetitionAndConsumerLaw,
  globalMobilityServices: GlobalMobilityServices,
  superannuation: Superannuation,
  technologyAndDigitalEconomy: TechnologyAndDigitalEconomy,
  consumerCredit: ConsumerCredit,
  insolvencyAndRestructuring: InsolvencyAndRestructuring,
  tax: Tax,
  educationAndTraining: EducationAndTraining,
  corporateAndCommercial: CorporateAndCommercial,
  insurance: Insurance,
  turnaroundAndBusinessRestructuring: TurnaroundAndBusinessRestructuring,
  energyResourcesAndUtilities: EnergyResourcesAndUtilities,
  cyber: Cyber,
  intellectualProperty: IntellectualProperty,
  workHealthSafety: WorkHealthSafety,
  fashionAndBeauty: FashionAndBeauty,
  debtRecovery: DebtRecovery,
  litigationAndDisputeResolution: LitigationAndDisputeResolution,
  gaming: Gaming,
  defamation: Defamation,
  mergersAndAcquisitions: MergersAndAcquisitions,
  sportsAndEntertainment: SportsAndEntertainment,
  employmentAndWorkplaceRelations: EmploymentAndWorkplaceRelations,
  migrationServices: MigrationServices,
  transportAndTrade: TransportAndTrade,
  environmentSocialAndGovernance: EnvironmentSocialAndGovernance,
  privacy: Privacy,
};

export const appIcons = {
  news: Newspaper,
  insight: Insight,
  email: Email,
  error: Error,
  link: Link,
  check: Check,
  twitter: Twitter,
} as const;

export const customIcons = {
  eye: Eye,
  eyeOff: EyeOff,
  lock: Lock,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  arrowRight: ArrowRight,
  arrowLeft: ArrowLeft,
  arrowUp: ArrowUpward,
  arrowDown: ArrowDownward,
  returnRight: ReturnRight,
  menu: Menu,
  search: Search,
  close: Close,
  event: Event,
  podcast: Podcasts,
  video: Video,
  share: Share,
  download: Download,
  minus: Minus,
  plus: Plus,
  facebook: Facebook,
  linkedin: LinkedIn,
  youtube: YouTube,
  instagram: Instagram,
  calendar: Calendar,
  ...areaIcons,
};

export const icons = { ...appIcons, ...customIcons } as const;

export type AppIcons = typeof appIcons;
export type AppIcon = AppIcons[AppIconName];
export type AppIconName = keyof AppIcons;

export type CustomIcons = typeof customIcons;
export type CustomIcon = CustomIcons[CustomIconName];
export type CustomIconName = keyof CustomIcons;

export type Icons = typeof icons;
export type Icon = Icons[IconNames];
export type IconNames = AppIconName | CustomIconName;
export type { IconNames as IconName };

export type IconProps<
  D extends React.ElementType = SvgIconTypeMap['defaultComponent'],
  P = NoProps
> = SvgIconProps<
  D,
  {
    name: IconNames;
    className?: string;
  } & SprinklesProps &
    P
>;
const Icon = ({ name, ...rest }: IconProps) => {
  const Component = icons[name];
  const props = useSprinklesProps(rest);

  const sx = {
    fontSize: style.iconVars.fontSize,
    ...props.sx,
  };

  // If name equals the key of any of the customIcons, render the custom icon
  if (name in customIcons)
    return (
      <SvgIcon
        fontSize="inherit"
        // Causes issues with some svgs
        // inheritViewBox={true}
        viewBox="0 0 32 32"
        {...props}
        sx={sx}>
        <Component />
      </SvgIcon>
    );

  return <Component fontSize="inherit" {...props} sx={sx} />;
};

export default Icon;
